<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Reoder wizard steps" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="grid">
        <OrderList v-model="items" listStyle="height:250px" dataKey="id" :rows="10">
          <template #item="slotProps">
            <div>{{slotProps.item.step.title}}</div>
          </template>
        </OrderList>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import WizardStepService from "@/service/WizardStepService";

export default {
  name: "ReorderStepForm",
  emits: ['hideDialog','saved'],
  props: {
    wizardId: String,
    dialog: Boolean
  },
  entityService: null,

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
  },

  created() {
    this.entityService = new WizardStepService();
  },

  mounted() {
    this.entityService.fetchAll(this.wizardId).then(data => this.items = data.data);
  },

  data() {
    return {
      submitted: false,
      items: []
    }
  },

  methods: {
    save() {
      this.submitted = true;
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].order = i;
      }

      this.entityService.bulkReorder(this.items).then(() => this.$emit('saved'));
    },
  }
}
</script>

<style scoped>

</style>
